import styled from 'styled-components'

import {
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
  TdInnerStyled,
  ThInnerStyled,
} from '../../../components/RedaptiveReactTable7'
import { colors } from '../../../utils/themes'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 30px;
  position: absolute;
  top: 10px;
`

export const TableWrapperStyled = styled.div`
  position: relative;
  button[type='redaptiveTransparent'] {
    position: absolute;
    right: 420px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  tr {
    &:nth-child(odd) {
      background-color: ${colors.gray9};
    }
    &:nth-child(even) {
      background-color: ${colors.white};
    }
  }
  margin-top: 50px;
  td {
    padding: 10px 0;
    &:first-child {
      > ${TdInnerStyled} {
        padding-left: 10px;
      }
    }
  }
  td {
    background-color: inherit;
    border: none;
  }
  thead {
    border: none;
  }
  th {
    &:first-child {
      ${ThInnerStyled} {
        padding-left: 0px;
      }
    }
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: ${colors.gray};
    font-family: 'Avenir Next';
    input {
      width: 65%;
      padding-left: 10px !important;
      margin: 0;
    }
    .material-icons {
      display: none;
    }
  }
  ${ReactTableTopSectionStyles} {
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.gray3};
    margin: 0;
  }
  ${ReactTableWrapperStyles} {
    margin-top: 30px;
    overflow-x: scroll;
    border: none;
  }
`
